import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { IApiGallery, IPaginationConfig, TGalleryTreeNode } from '@interfaces';

export const galleryActions = {
  /* FETCH FOR YEARS */

  fetchForYears: createAction('[Galleries] FETCH_FOR_YEARS', props<{ start: number | string; end: number | string; reset?: boolean }>()),
  fetchForYearsSuccess: createAction('[Galleries] FETCH_FOR_YEARS_SUCCESS', props<{ galleries: IApiGallery[]; reset?: boolean }>()),

  /* FETCH TREE DATA */

  fetchTreeData: createAction('[Galleries] FETCH_TREE_DATA'),
  fetchTreeDataSuccess: createAction('[Galleries] FETCH_TREE_DATA_SUCCESS', props<{ data: TGalleryTreeNode[] }>()),

  /* FETCH ONE */

  fetchOne: createAction('[Galleries] FETCH_ONE', props<{ uuid: string; admin?: boolean; details?: boolean }>()),
  fetchOneSuccess: createAction('[Galleries] FETCH_ONE_SUCCESS', props<{ gallery: IApiGallery }>()),

  /* FETCH */
  fetch: createAction('[Galleries] FETCH', props<IPaginationConfig>()),
  fetchSuccess: createAction('[Galleries] FETCH_SUCCESS', props<{ entities: IApiGallery[]; total: number }>()),

  /* PLAY */

  play: createAction('[Gallery] PLAY', props<{ galleryUuid: string; index?: number }>()),

  /* SET_LOADING */

  setLoading: createAction('[Gallery] SET_LOADING', props<{ value: boolean }>()),

  /* RESET */

  reset: createAction('[Gallery] RESET'),

  /* CREATE */

  create: createAction('[Galleries] CREATE', props<{ entity: Partial<IApiGallery> }>()),
  createSuccess: createAction('[Galleries] CREATE_SUCCESS', props<{ gallery: IApiGallery }>()),
  createError: createAction('[Galleries] CREATE_ERROR', props<{ error: HttpErrorResponse }>()),

  /* UPDATE */

  update: createAction('[Galleries] UPDATE', props<{ entity: Partial<IApiGallery> }>()),
  updateSuccess: createAction('[Galleries] UPDATE_SUCCESS', props<{ gallery: IApiGallery }>()),
  updateError: createAction('[Galleries] UPDATE_ERROR', props<{ error: HttpErrorResponse }>()),

  /* DESTROY */

  destroy: createAction('[Galleries] DESTROY', props<{ uuid: string }>()),
  destroySuccess: createAction('[Galleries] DESTROY_SUCCESS', props<{ uuid: string }>()),
  destroyError: createAction('[Galleries] DESTROY_ERROR', props<{ error: HttpErrorResponse }>()),

  /* ERRORS */

  fetchError: createAction('[Galleries] FETCH_ERROR', props<{ error: HttpErrorResponse }>()),
};
