import { createFeature } from '@ngrx/store';
import { reducer } from './galleries.reducer';
import { gallerySelectors } from './galleries.selectors';
import { galleryActions } from '@store/gallery/galleries.actions';

export { gallerySelectors, galleryActions };

export const galleriesFeature = createFeature({
  name: 'galleries',
  reducer,
  extraSelectors: () => gallerySelectors,
});
