import { Action, createReducer, on } from '@ngrx/store';

import { galleryActions } from './galleries.actions';

import { IApiGallery, TGalleryTreeNode } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface IGalleryState extends EntityState<IApiGallery> {
  current: { id: number; picIndex: number };
  treeData: TGalleryTreeNode[];
  total: number;
  loading: boolean;
  updated: number;
}

const adapter: EntityAdapter<IApiGallery> = createEntityAdapter();

export const initialState = adapter.getInitialState({
  current: null,
  treeData: null,
  total: null,
  loading: false,
  updated: null,
});

export const reducer = createReducer(
  initialState,

  // FetchForYears

  on(galleryActions.fetchForYearsSuccess, (state, { galleries, reset }) => {
    const next = { ...state, loading: false, updated: Date.now() };
    return reset ? adapter.setAll(galleries, next) : adapter.upsertMany(galleries, next);
  }),

  // FetchTreeData

  on(galleryActions.fetchTreeDataSuccess, (state, { data }) => ({
    ...state,
    treeData: data.map(({ startYear, endYear, children }) => ({
      startYear,
      endYear,
      children,
      path: `/galleries/${startYear}-${endYear}`,
      label: `${startYear}-${endYear}`,
    })),
    loading: false,
    updated: Date.now(),
  })),

  // Fetch

  on(galleryActions.fetchSuccess, (state, { entities, total }) =>
    adapter.setAll(entities, { ...state, total, loading: false, updated: Date.now() })
  ),

  // FetchOne

  on(galleryActions.fetchOne, state => ({ ...state, loading: true })),
  on(galleryActions.fetchOneSuccess, (state, { gallery }) => adapter.upsertOne(gallery, { ...state, loading: false, updated: Date.now() })),

  // Misc

  on(galleryActions.setLoading, (state, { value }) => ({ ...state, loading: value, updated: Date.now() })),
  on(galleryActions.reset, state => adapter.removeAll({ ...state, loading: false, updated: Date.now() })),

  // Create

  on(galleryActions.createSuccess, (state, { gallery }) => adapter.addOne(gallery, { ...state, loading: false, updated: Date.now() })),
  on(galleryActions.createError, (state, { error }) => ({ ...state, error, loading: false })),

  // Update

  on(galleryActions.updateSuccess, (state, { gallery }) =>
    adapter.upsertOne(gallery, {
      ...state,
      loading: false,
      updated: Date.now(),
    })
  ),

  // Destroy

  on(galleryActions.destroySuccess, (state, { uuid }) => adapter.removeOne(uuid, { ...state, loading: false, updated: Date.now() })),

  // Common

  on(galleryActions.fetchError, galleryActions.updateError, galleryActions.destroyError, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),

  on(
    galleryActions.fetchForYears,
    galleryActions.fetchTreeData,
    galleryActions.create,
    galleryActions.fetch,
    galleryActions.fetchOne,
    galleryActions.update,
    galleryActions.destroy,
    state => ({
      ...state,
      loading: true,
    })
  )
);
